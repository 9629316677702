import React from 'react';
import Header from './components/templates/header/header';
import './App.css';

function App() {
  return (
    <div className="App">
      <Header></Header>
      <footer>
        <p>&copy; 2020</p>
      </footer>
    </div>
  );
}

export default App;
