import React, { Component } from 'react';
import Sound from 'react-sound';
import Fart from '../../elements/fart/fart.js';
import logo from './fartingcats.png';
import './header.css';

class Header extends Component {
  constructor(props) {
    super(props);
    this.toggleClass = this.toggleClass.bind(this);
    this.state = {
      isActive: false,
    };
  }

  toggleClass() {
    this.setState({
      isActive: !this.state.isActive
    });

    this.timeoutId = setTimeout(function () {
      this.setState({
        isActive: !this.state.isActive
      });
    }.bind(this), 3000);
  }

  render() {
    return (
      <header className="App-header">
        <h1 className="slanted"> Farting Cats </h1>
        <section className="hero" onClick={this.toggleClass}>
          <img src={logo} className={this.state.isActive ? 'App-logo App-logo-clicked': 'App-logo'} alt="farting cats"/>
        </section>
        <Fart playStatus={this.state.isActive ? Sound.status.PLAYING: Sound.status.STOPPED} />
        <p>
          Release the tension, pull the cats paw!
        </p>
      </header>
    );
  }

}

export default Header;
