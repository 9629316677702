import React, { Component } from 'react';
import soundfile from './fart.mp3';
import Sound from 'react-sound';

export default class Fart extends Component {
  render() {
    return ( <
      Sound url = {
        soundfile
      }
      playStatus = {
        this.props.playStatus
      }
      onLoading = {
        this.handleSongLoading
      }
      onPlaying = {
        this.handleSongPlaying
      }
      onFinishedPlaying = {
        this.handleSongFinishedPlaying
      }
      />
    );
  }
}
